/* ---------------------------------------------------- */
/* ----- MAIN ----- */
/* ---------------------------------------------------- */

/* --- agency container --- */
.agency {
  display: flex;
  flex-direction: column;

  @media #{$mobile_land__576} {
  }

  @media #{$tablet__768} {
    flex-direction: row;
    justify-content: space-between;
    // padding-bottom: 55px;
  }

  @media #{$desktop__1200} {
    padding-bottom: 3.438rem;
  }

  &-box {
    flex-basis: 100%;
    width: 100%;
    padding: 1.25rem;
    text-align: center;
    color: $port-gore;

    @media #{$desktop__1200} {
      text-align: left;
      flex-basis: 50%;
      width: 50%;
      padding: 5.188rem 0 0 3.938rem;
    }

    @media #{$large__screens__1920} {
      // padding-left: 14.19rem;
      padding: 7.938rem;
    }

    &__title {
      font-size: 1.5rem;
      font-weight: 700;
      color: $port-gore;
      text-transform: uppercase;
      position: relative;

      @media #{$desktop__1200} {
        font-size: 2rem;
        padding-bottom: 2.375rem;
      }

      &--color {
        color: $supernova;
        font-size: 1rem;
        font-weight: 500;

        @media #{$desktop__1200} {
          font-size: 1.5rem;
        }
      }

      &--dash::after {
        content: "";
        display: block;
        border-top: 0.125rem solid $supernova;
        width: 40px;
        position: absolute;
        bottom: -0.5rem;
        left: 50%;
        transform: translateX(-50%);

        @media #{$desktop__1200} {
          left: 0;
          // bottom: -0.25rem;
          bottom: 0.125rem;
          transform: translateX(0);
        }
      }
    }

    &__text {
      padding-top: 1.25rem;
      font-size: 0.875rem;
      font-weight: 300;

      @media #{$desktop__1200} {
        padding: 0 2.125rem 0 4.563rem;
        font-size: 1.125rem;
      }
    }
  }

  &__image {
    display: none;

    @media #{$tablet__768} {
      display: block;
      margin: 0 auto;
    }
  }
}

/* --- end of agency container --- */

/* --- projects container --- */
.projects {
  display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  // width: 100%;
  // height: 100%;

  overflow-y: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 0;
  padding-bottom: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  // @media #{$desktop__1200} {
  //   flex-wrap: nowrap;
  //   width: 100%;
  // }

  &-content {
    // width: 100%;
    position: relative;
    // margin: auto;
    // overflow: hidden;

    scroll-snap-align: center;
    width: 80%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$tablet__768} {
      // flex-basis: 50%;
      width: 30%;
    }

    @media #{$desktop__1200} {
      // flex-basis: 100%;
      width: 25%;
    }

    @media #{$large__screens__1920} {
      width: 20%;
    }

    &__image {
      position: relative;
      overflow: hidden;

      &::after {
        content: "";
        background: $overlay-background;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

    &__details {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: 0;
      left: 5%;
      width: 80%;
      // height: 119px;
      height: 130px;
      overflow: hidden;
      color: white;
      padding: 10px;

      @media #{$tablet__768} {
        // height: 186px;
        height: 165px;
        padding: 20px;
        left: 10%;
      }

      @media #{$desktop__1200} {
        // height: 235px;
        height: 215px;
      }

      &-img {
        width: 11%;
      }

      &-heading {
        padding-top: 0.625rem;

        @media #{$tablet__768} {
          padding-top: 1.75rem;
        }
        @media #{$desktop__1200} {
          padding-top: 2.375rem;
        }

        &__cat {
          font-size: 0.6875rem;
          font-weight: 400;
        }

        &__title {
          font-size: 1.563rem;
          font-weight: 700;
          text-transform: uppercase;
          padding-bottom: 1rem;

          @media #{$tablet__768} {
            font-size: 1.875rem;
          }

          @media #{$desktop__1200} {
            font-size: 2.688rem;
          }
        }
      }

      &-list {
        font-size: 0.875rem;

        @media #{$tablet__768} {
          font-size: 1rem;
        }
      }
    }

    &:hover {
      & .projects-content__details {
        width: 90%;
        height: 90%;
        bottom: 5%;
        left: 5%;
      }

      & .projects-content__image::after {
        content: "";
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        transform: scale(0.9);
        background: $overlay-background-darken;
      }
    }
  }
}
/* --- end of projects container --- */

/* --- customers container --- */
.customers {
  background-color: $supernova;
  // border-top: 0.125rem solid $white;
  position: relative;
  width: 100%;
  display: block;

  &-pattern {
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23fce200' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");

    &::before {
      content: "";
      display: block;
      width: 67px;
      height: 35px;
      border-top: solid 2.313rem white;
      border-left: solid 2.313rem transparent;
      border-right: solid 2.375rem transparent;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      left: 50%;
      z-index: 2;
    }
  }

  &-content {
    padding: 0 1.25rem;
    text-align: center;
    display: flex;
    flex-direction: column;

    @media #{$tablet__768} {
      padding: 0;
    }

    @media #{$desktop__1200} {
      text-align: left;
      padding: 0 0 30px;
    }
  }

  &__title {
    @media #{$desktop__1200} {
      transform: translateX(2%);
    }

    &--first {
      font-size: 1.5rem;
      color: $port-gore;
      padding-top: 2.813rem;

      @media #{$tablet__768} {
      }

      @media #{$desktop__1200} {
        font-size: 2rem;
      }

      &::after {
        content: "";
        display: block;
        border-bottom: 0.125rem solid $white;
        padding-top: 0.3125rem;
        width: 260px;
        margin: 0 auto;

        @media #{$tablet__768} {
          width: 287px;
          border-bottom: 0.1875rem solid $white;
        }

        @media #{$desktop__1200} {
          margin: 0;
        }
      }
    }

    &--second {
      font-size: 1.125rem;
      padding: 1.25rem 0;
      color: $white;
      font-weight: 500;

      @media #{$desktop__1200} {
        font-size: 1.5rem;
      }
    }
  }

  &__badges {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    // align-items: center;
    width: 100%;
    max-width: 70rem;
    margin: 0 auto;

    &-box {
      // width: 72%;
      // width: 42%;
      // margin: 0.625rem;
      width: 92%;
      margin: 0.625rem 0;
      border-radius: 50%;
      overflow: hidden;
      // justify-self: center;

      // @media #{$tablet__768} {
      //   width: 33%;
      // }

      // @media #{$desktop__1200} {
      //   width: 17%;
      // }
    }
  }

  .siema {
    margin: 1rem 0;
  }

  &-brief {
    padding: 1.875rem 1.25rem 0;

    &__box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 2.688rem;

      @media #{$tablet__768} {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        @media #{$desktop__1200} {
          justify-content: start;
          padding-right: 10.38rem;
          font-size: 1.875rem;
        }
      }

      &--text {
        font-size: 1.25rem;
        font-weight: 500;
        color: $white;
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 1.25rem;

        @media #{$tablet__768} {
          font-size: 1.563rem;
          padding-bottom: 0;
        }

        @media #{$desktop__1200} {
          padding-right: 1.875rem;
          font-size: 1.25rem;
        }
      }

      &--btn {
        border: 0.125rem solid $port-gore;
        padding: 0.9375rem 1.875rem;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;

        @media #{$tablet__768} {
          border: 0.1875rem solid $port-gore;
          padding: 1rem 4.438rem;
          font-size: 0.9375rem;
        }

        @media #{$desktop__1200} {
          font-size: 1.25rem;
        }

        &__anchor {
          color: $port-gore;
        }
      }
    }
  }

  &-testimonial {
    position: relative;
    background: url("../images/testimonial_bg.jpg") no-repeat center center;
    background-size: cover;
    min-height: calc(739px / 2);

    &-content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1.25rem 1.25rem 0.625rem;
      // height: 370px;
      height: 480px;

      @media #{$tablet__768} {
        height: 465px;
      }

      &__heading {
        color: $white;
        &--title {
          font-weight: 500;
          font-size: 1.25rem;
          padding-bottom: 0.25rem;

          @media #{$tablet__768} {
            font-size: 2rem;
            padding-bottom: 0.5rem;
          }

          &::after {
            content: "";
            padding-top: 0.375rem;
            border-bottom: 0.125rem solid #fac800;
            width: 80px;
            display: block;

            @media #{$tablet__768} {
              font-size: 2rem;
              padding-bottom: 0.5rem;
            }
          }
        }

        &--text {
          font-size: 0.9375rem;
          font-weight: 400;
          padding: 0;

          @media #{$tablet__768} {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

/* --- slider --- */

.slider {
  max-width: 1530px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: auto;
  position: relative;
}

.all-reviews {
  position: relative;
  margin: 12% 0;

  @media #{$tablet__768} {
    margin: 6% 0;
  }

  @media #{$desktop__1200} {
    margin: 3% 0;
  }
}

.review {
  // width: 350px;
  width: 88%;
  position: absolute;
  // left: 375px;
  left: 112%;
  color: $white;

  &__text {
    font-size: 1.125rem;
    font-size: 0.8125rem;
    font-weight: 300;
    padding-bottom: 1.875rem;

    @media #{$tablet__768} {
      // font-size: 1.75rem;
      font-size: 0.9375rem;
      padding-bottom: 1.25rem;
    }

    @media #{$desktop__1200} {
      // font-size: 2.25rem;
      font-size: 1.063rem;
      padding-bottom: 1.625rem;
    }

    @media #{$large__screens__1920} {
      padding-bottom: 2.5rem;
    }
  }

  &__person {
    display: flex;
    align-items: center;

    @media #{$tablet__768} {
      align-self: flex-end;
      padding-right: 1.25rem;
    }

    @media #{$desktop__1200} {
      padding-right: 4.125rem;
    }

    &-box {
      padding-left: 0.75rem;

      &__name {
        font-size: 1rem;
        font-weight: 700;

        @media #{$tablet__768} {
          font-size: 1.25rem;
        }

        @media #{$desktop__1200} {
          font-size: 1.5rem;
        }
      }

      &__branch {
        font-size: 0.8125rem;
        padding-bottom: 0;

        @media #{$tablet__768} {
          font-size: 0.875rem;
        }

        @media #{$desktop__1200} {
          font-size: 1rem;
        }
      }
    }

    &::before {
      content: "";
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $supernova;

      @media #{$tablet__768} {
        width: 50px;
        height: 50px;
      }

      @media #{$desktop__1200} {
        width: 75px;
        height: 75px;
      }
    }
  }
}

.position {
  display: flex;
  flex-direction: column;
}

.center {
  // transform: translateX(-375px);
  transform: translateX(-124%);
}

.left {
  // transform: translateX(-750px);
  transform: translateX(-248%);
}

.smooth {
  transition: transform 1s;
}

#slider-buttons {
  position: absolute;
  width: 100%;
  bottom: 5%;
  left: 4%;
  text-align: center;
  display: flex;
}

.slide--btn {
  display: inline-block;
  border-radius: 50%;
  width: 0.6875rem;
  height: 0.6875rem;
  transition: background-color 0.4s;
  background-color: #fff;
  text-indent: -999px;
  margin: 0.375rem;
  cursor: pointer;

  &:hover {
    background-color: $gray;
  }

  &.selected {
    background-color: $supernova;
  }
}

/* --- end of slider --- */

/* --- end of customers container --- */

.offer {
  background: $supernova;
  position: relative;

  &-text {
    text-align: center;
    padding: 1.688rem 0;
    position: relative;

    /* utworzyć mixin */
    &__title {
      font-size: 1.5rem;
      font-weight: 500;
      color: $port-gore;
      position: relative;

      @media #{$tablet__768} {
        font-size: 2rem;
      }

      &::after {
        content: "";
        border-bottom: 0.125rem solid $white;
        display: block;
        margin: 0 auto;
        width: 200px;
        padding-top: 0.4375rem;

        @media #{$tablet__768} {
          border-bottom: 0.1875rem solid $white;
          width: 280px;
        }
      }
    }

    &__par {
      font-size: 1.125rem;
      color: $white;
      padding: 0;

      @media #{$tablet__768} {
        font-size: 1.5rem;
      }
    }

    &::after {
      content: "";
      display: block;
      width: 67px;
      height: 35px;
      border-top: solid 2.313rem $triangle;
      border-left: solid 2.313rem transparent;
      border-right: solid 2.375rem transparent;
      position: absolute;
      bottom: -37px;
      transform: translateX(-50%);
      left: 50%;
      z-index: 2;
    }
  }

  &-content {
    width: 100%;
    height: auto;
    height: 100%;
    background: url("../images/bg_offer.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &__grid {
      display: flex;
      justify-content: center;
      padding: 4.375rem 0 3.125rem;

      @media #{$tablet__768} {
        padding: 6.25rem 0 4.375rem;
      }

      // @media #{$desktop__1200} {
      //   margin: 0 1.25rem;
      // }

      &-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        grid-gap: 1.813em;
        justify-items: center;
        width: calc(100% - 20px);

        @media #{$tablet__768} {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, 178px);
          justify-items: center;
          justify-content: space-around;
          width: calc(100% - 30px);
        }

        @media #{$desktop__1200} {
          grid-template-columns: repeat(2, 548px);
          justify-content: center;
        }

        @media #{$large__screens__1920} {
          grid-template-columns: repeat(2, 578px);
        }

        &__box {
          width: 100%;
          height: auto;
          background: $white;
          display: flex;
          align-items: center;
          padding: 1.25rem 0.625rem;

          @media #{$tablet__768} {
            align-items: start;
            padding: 2.188rem 0 0 1.125rem;
          }

          @media #{$desktop__1200} {
            padding: 2.188rem 2.813rem 0;
          }

          &-icon {
            width: 13%;
            flex-basis: 13%;
            display: inline-flex;
            align-self: start;
            padding-top: 0.9375rem;

            @media #{$tablet__768} {
              width: 18%;
              flex-basis: 18%;
              display: inline-flex;
              padding-top: 1.25rem;
            }

            @media #{$desktop__1200} {
              width: 10%;
              flex-basis: 10%;
            }
          }

          &-text {
            font-weight: 400;
            width: 87%;
            flex-basis: 87%;
            padding-left: 0.9375rem;

            @media #{$tablet__768} {
              width: 82%;
              flex-basis: 82%;
            }

            @media #{$desktop__1200} {
              width: 90%;
              flex-basis: 90%;
              padding-left: 2.5rem;
            }

            &__title {
              font-size: 0.9375rem;
              color: $port-gore;
              text-transform: uppercase;
              padding-bottom: 0.75rem;
            }

            &__list {
              list-style: none;
              font-size: 0.8125rem;
              color: $dusty-gray;
            }
          }
        }
      }
    }
  }
}
/* ----- END OF MAIN ----- */

@media #{$mobile_land__576} {
}

@media #{$tablet__768} {
}

@media #{$desktop__1200} {
}

@media #{$large__screens__1920} {
}
