// ----- Modules -----
@import "modules/variables";
@import "modules/mixins";
@import "modules/placeholders";
@import "modules/functions";

// ----- Base -----
@import "base/global";

// ----- Partials -----
@import "partials/header";
@import "partials/main";
@import "partials/footer";
